<template>
  <div class="step2">
    <div class="book">
      11
    </div>
    <div class="pagination">
      <img class="item" src="@/assets/display/step3/prev.png" alt="" />
      <img class="item" src="@/assets/display/step3/next.png" alt="" />
    </div>
  </div>
</template>

<script>
import { getShowAllDataById } from "@/api/fenxi.js";
export default {
  filters: {
    weekday(val) {
      var date = new Date(val);
      var dayIndex = date.getDay(); //获取存储当前日期
      var weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      return weekday[dayIndex];
    }
  },
  data() {
    return {
      id: 0,
      info: {}
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getShowAllDataById();
  },
  methods: {
    getShowAllDataById() {
      getShowAllDataById(this.id).then(res => {
        this.$set(this, "info", res.data);
        this.info.zteam_imageid = this.info.zteam_imageid.replace(
          /small/,
          "big"
        );
        this.info.kteam_imageid = this.info.kteam_imageid.replace(
          /small/,
          "big"
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.step2 {
  background-image: url("../../assets/display/step3/bg.png");
  background-repeat: no-repeat;
  min-height: 1080px;
  background-position: center top;
  position: relative;
  .book {
    width: 1475px;
    height: 914px;
    background-image: url("../../assets/display/step3/book.png");
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translate(-50%, 0);
    .day {
      text-align: center;
      font-family: SentyGoldenBell;
      position: absolute;
      left: 237px;
      top: 308px;
      font-size: 34px;
      color: #472309;
      line-height: 42px;
    }
    .team {
      position: absolute;
      right: 93px;
      top: 65px;
      .team-item {
        font-family: SentyMARUKO;
        font-size: 35px;
        font-weight: 400;
        color: #472309;
        line-height: 57px;
        writing-mode: vertical-lr;
        .img {
          height: 50px;
        }
      }
      .vs {
        font-size: 41px;
        font-weight: bold;
        color: #472309;
        line-height: 61px;
      }
    }
  }
  .pagination {
    width: 700px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    top: 965px;
    transform: translate(-50%, 0);
    .item {
      cursor: pointer;
    }
  }
}
</style>
